export const allcomponents = [
	{ component: 'Button' },
	{ component: 'Slider' },
	{ component: 'Stepper' },
	{ component: 'Switch' },

	{ component: 'Text Field' },
	{ component: 'Tab Bar' },
	{ component: 'Search Bar' },
	{ component: 'Tags' },
	{ component: 'Illustration' },
	{ component: 'Map Pin' },

	{ component: 'Table' },

	{ component: 'Gallery' },
	{ component: 'Splash Screen' },
	{ component: 'Icon' },
	{ component: 'Heart Icon' },
	{ component: 'Input' },
	{ component: 'Map' },
	{ component: 'Modal' },

	{ component: 'Tooltip' },
	{ component: 'Toast' },
	{ component: 'Time Picker' },

	{ component: 'Date Picker' },

	{ component: 'Stickers' },
	{ component: 'Success State' },
	{ component: 'Steps' },

	{ component: 'Status Indicator' },
	{ component: 'Share' },
	{ component: 'Sidebar' },
	{ component: 'Range Slider' },
	{ component: 'Share Buttons' },
	{ component: 'Search' },
	{ component: 'Radio Input' },

	{ component: 'Qr Code' },
	{ component: 'Progress Bar' },
	{ component: 'Pricing Tiers' },

	{ component: 'Pin Code Input' },
	{ component: 'Popup' },
	{ component: 'Bottom Sheet' },

	{ component: 'Password Input' },
	{ component: 'Page Indicator' },
	{ component: 'Pagination' },

	{ component: 'Overlay' },
	{ component: 'Number Step Input' },
	{ component: 'Number Input' },

	{ component: 'Messages' },
	{ component: 'Multi-step' },
	{ component: 'Masonry' },
	{ component: 'Links' },
	{ component: 'Keypad' },
	{ component: 'Social' },
	{ component: 'Leaderboard' },
	{ component: 'List' },
	{ component: 'Loading Indicator' },
	{ component: 'Location Search' },

	{ component: 'Image Upload' },
	{ component: 'Inbox' },
	{ component: 'Image Gallery' },
	{ component: 'In-app Purchase' },
	{ component: 'Hero Header' },

	{ component: 'Horizontal Scroll' },
	{ component: 'Guide' },
	{ component: 'Grid' },
	{ component: 'Form' },
	{ component: 'Feed' },
	{ component: 'Pill' },
	{ component: 'Filter' },
	{ component: 'Error State' },
	{ component: 'Empty State' },

	{ component: 'Dropdown' },

	{ component: 'Date Input' },

	{ component: 'Dark Mode' },
	{ component: 'Emoji' },

	{ component: 'Crop Image' },
	{ component: 'Dashboard' },

	{ component: 'Credit Card Input' },
	{ component: 'Countdown' },
	{ component: 'Navbar' },
	{ component: 'Confirm' },
	{ component: 'Confetti' },
	{ component: 'Comment' },
	{ component: 'Comment Input' },
	{ component: 'Checklist' },
	{ component: 'Checkbox' },
	{ component: 'Chat' },
	{ component: 'Chat Bot' },
	{ component: 'Chart' },

	{ component: 'Carousel' },
	{ component: 'Card' },
	{ component: 'Captcha' },
	{ component: 'Accordion' },
	{ component: 'Canva' },
	{ component: 'Timer' },
	{ component: 'Calendar' },
	{ component: 'Calculation' },
	{ component: 'Taglines' },
	{ component: 'Autocomplete' },
	{ component: 'Audio Recording' },
	{ component: 'Audio Player' },
	{ component: 'Information Icon' },
	{ component: 'Alert' },
	{ component: 'Agree To Terms' },
	{ component: 'Ad' },
	{ component: 'Add Address' },
	{ component: 'Disabled Button' },
	{ component: 'Text' },
	{ component: 'Read More' },
	{ component: 'Divider' },
	{ component: 'Profile Picture' },
].sort((a: any, b: any) => {
	let fa = a.component.toLowerCase(),
		fb = b.component.toLowerCase();

	if (fa < fb) {
		return -1;
	}
	if (fa > fb) {
		return 1;
	}
	return 0;
});
