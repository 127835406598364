import { useRouter } from 'next/router';
import { createContext, useEffect, useState } from 'react';
import { supabase, getSession, updateUserProfileInfo } from '../supabase';
import axios from 'axios';
import { Session } from "@supabase/supabase-js";
export const UserContext = createContext(null);
export const UserCountryContext = createContext(null);

export const UserContextProvider = ({ children }) => {
	const router = useRouter();
	const [user, setUser] = useState(null);
	const [country, setCountry] = useState('Nigeria');

	useEffect(() => {
		async function getUser() {
		  const session: Session | null = await getSession();
		  if (session) {
			return setUser(session.user);
		  }
	
		  return setUser(null);
		}
		getUser();
	  }, []);

	useEffect(() => {
		const setUserCountry = async () => {
		  try {
			const response = await axios("https://uiland.design/api/getcountry");
			const { country } = response.data;
			if (country == "Nigeria") {
			  setCountry("Nigeria");
			} else {
			  setCountry(country);
			}
		  } catch (error) {
			console.log("an error occurred while trying to retrieve country");
			setCountry("Nigeria");
		  }
		};
		setUserCountry();
	  }, []);
	

	//supabase auth listener for changes
	useEffect(() => {
		async function authListener() {
		  supabase.auth.onAuthStateChange((event: string, session: any) => {
			if (event == "SIGNED_OUT") {
			  setUser(null);
			  router.push("/");
			}
		  });
		}
		authListener();
	  }, []);

	useEffect(() => {
		const supabaseAuth = async () => {
			const session = await getSession();
			if (session) {
				
				await updateUserProfileInfo(session.user, country); //update the user profile document with the user's visiting country
				return setUser(session.user);
			}
			return setUser(null);
		};
		supabaseAuth();
	}, [country]); //only update user to state when country has been fetched

	return (
		//different context providers for country and users instead of a single value object for performance reasons
		// value={country,data} would create a new copy of the object on each mount causing rerenders even when values of country and data dont change
		<UserCountryContext.Provider value={country}>
			<UserContext.Provider value={user}>{children}</UserContext.Provider>
		</UserCountryContext.Provider>
	);
};
