import { useEffect, useState } from 'react';

import Script from 'next/script';
import { useRouter } from 'next/router';
import { AppProps } from 'next/app';

//Packages
import { hotjar } from 'react-hotjar';
import { Analytics } from '@vercel/analytics/react';

import { createBrowserSupabaseClient } from '@supabase/auth-helpers-nextjs';
import { SessionContextProvider } from '@supabase/auth-helpers-react';
import { Session } from '@supabase/auth-helpers-react';

import '../styles/globals.css';

//Google tag manager
import * as gtag from '../lib/gtag';

//Context
import { UserContextProvider } from '../context/authContext';
import { ScreensContextProvider } from '../context/screensContex';

//Components
import Layout from '../components/layout';

export default function App({
	Component,
	pageProps,
}: AppProps<{
	initialSession: Session;
}>) {
	const router = useRouter();
	useEffect(() => {
		const handleRouteChange = (url: string) => {
			gtag.pageview(url);
		};
		router.events.on('routeChangeComplete', handleRouteChange);
		router.events.on('hashChangeComplete', handleRouteChange);
		return () => {
			router.events.off('routeChangeComplete', handleRouteChange);
			router.events.off('hashChangeComplete', handleRouteChange);
		};
	}, [router.events]);

	//This runs the hotjar script
	//The false is used to prevent development debugging
	useEffect(() => {
		hotjar.initialize(3322744, 6, false);
	}, []);

	const [supabaseClient] = useState(() => createBrowserSupabaseClient());

	return (
		<SessionContextProvider
			supabaseClient={supabaseClient}
			initialSession={pageProps.initialSession}
		>
			<UserContextProvider>
				<ScreensContextProvider>
					{/* script to embed Google Ads */}
					<Script
						data-ad-client='ca-pub-5952673528545779'
						async
						src='https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js'
					></Script>

<Script
        src="https://app.lemonsqueezy.com/js/lemon.js"
        strategy="lazyOnload"
      ></Script>


					{/* script to embed Pirsch analytics */}
					<Script
						strategy='afterInteractive'
						src='https://api.pirsch.io/pirsch.js'
						id='pirschjs'
						data-code='XngUnG5I01oUAdhsYd83P1D2WD3OeAQe'
					/>
					{/* script to embed Google tags */}
					<Script
						strategy='afterInteractive'
						src={`https://www.googletagmanager.com/gtag/js?id=${gtag.GA_TRACKING_ID}`}
					/>
					{/* script to embed Ethical Ads */}
					{/* <Script
						async
						src="https://media.ethicalads.io/media/client/ethicalads.min.js"
					/> */}
					{/* script to embed Google analytics */}
					<Script id='google-analytics' strategy='afterInteractive'>
						{`
				  window.dataLayer = window.dataLayer || [];
				  function gtag(){dataLayer.push(arguments);}
				  gtag('js', new Date());
				
				  gtag('config', '${gtag.GA_TRACKING_ID}');
					`}
					</Script>

					<Layout>
						<Component {...pageProps} />
					</Layout>
					<Analytics />
				</ScreensContextProvider>
			</UserContextProvider>
		</SessionContextProvider>
	);
}
